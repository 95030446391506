var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav"},[_c('div',{staticClass:"nav__topbar"},[_c('button',{staticClass:"nav__hamburger",attrs:{"id":"nav__hamburger","type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleMobileNavigation.apply(null, arguments)}}},[_c('hamburg-toggle',{attrs:{"cross":!_vm.minimal}})],1),_c('button',{staticClass:"nav__toggle",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleNavigation.apply(null, arguments)}}},[_c('i',{staticClass:"icon--sidebar"})]),_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":"/dashboard","role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('responsive-logo',{staticClass:"nav__logo",class:[_vm.minimal && 'djl-logo-as-minimal']})],1)]}}])})],1),_c('div',{staticClass:"nav-items-wrapper"},[_c('ul',{staticClass:"nav-items"},[_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('dashboard'),
          link: '/dashboard',
          icon: 'icon--home-alt',
        },"minimal":_vm.minimal}}),(_vm.$can('view', 'EmployeeTask'))?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('employee.employee_tasks'),
          link: "/taken?category=my",
          icon: 'icon--clipboard-user',
        },"minimal":_vm.minimal}}):_vm._e(),(_vm.$can('view', 'MyTeamTasks'))?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('employee.my_team_tasks'),
          link: "/taken?category=team",
          icon: 'icon--clipboard-alt',
        },"minimal":_vm.minimal}}):_vm._e(),(_vm.$can('view', 'MyActiveTasks'))?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('employee.my_active_tasks'),
          link: "/taken?category=active",
          icon: 'icon--process',
        },"minimal":_vm.minimal}}):_vm._e(),(_vm.$can('view', 'CustomerTask'))?_c('navigation-item',{attrs:{"item":_vm.customerTasks,"minimal":_vm.minimal}}):_vm._e(),(_vm.isShowKnowledgeBase)?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('knowledge_base'),
          link: '/kennisbank',
          icon: 'icon--books',
        },"minimal":_vm.minimal}}):_vm._e(),(_vm.$can('view', 'Dossier'))?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('dossier'),
          link: '/dossier',
          icon: 'icon--icon-folder-2',
        },"minimal":_vm.minimal}}):_vm._e(),(_vm.$can('view', 'UserManagement'))?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('user_management'),
          link: '/gebruikersbeheer',
          icon: 'icon--user-square',
        },"minimal":_vm.minimal}}):_vm._e(),(_vm.$can('view', 'Contact'))?_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('customer.contact'),
          link: '/contact',
          icon: 'icon--icon-comment-lines',
        },"minimal":_vm.minimal}}):_vm._e(),_c('navigation-item',{attrs:{"item":{
          title: _vm.translated_component_value('logout'),
          link: ("" + _vm.logout),
          icon: 'icon--signin-alt',
        },"minimal":_vm.minimal}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }