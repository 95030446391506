import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from "vuex-module-decorators";

import {
  AppAbility,
  AppRole,
  buildAbilityFor,
} from "@/lib/abilities/abilities";
import { KeycloakAttributes } from "@/lib/keycloak/KeycloakPlugin";
import { fromKeycloakRole, Permission } from "@/models/role";
import { extractRole, extractUserId } from "@/models/keycloak";
import { enableAnalytics } from "@/models/seo";
import access_control from "@/api/access_control";

export interface AccountPermission {
  accountId: string;
  permissions: Permission[];
}

export class AccountPermissionError extends Error {}

export type ProfileState =
  | "unloaded"
  | "loaded"
  | "loadedWithErrors"
  | "errorCritical";

@Module({ namespaced: true })
class User extends VuexModule {
  profileState: ProfileState = "unloaded";

  token: string | null = null;
  refreshToken: string | null = null;

  userId: string = "";
  username: string = "";
  firstName: string | null = null;
  lastName: string = "";
  email: string = "";
  role: AppRole | null = "guest";
  accountPermissions: Array<AccountPermission> | null = null;

  get fullName() {
    return this.firstName && this.lastName
      ? `${this.firstName} ${this.lastName}`
      : "";
  }

  get initials(): string {
    return this.firstName || this.lastName
      ? `${this.firstName?.charAt(0)}${this.lastName.charAt(0)}`.toUpperCase()
      : "";
  }

  @MutationAction({
    mutate: ["userId", "username", "email", "firstName", "lastName", "role"],
  })
  public async setProfile(payload: {
    roles: string[];
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    attributes: KeycloakAttributes;
  }) {
    const { username, email, firstName, lastName, attributes } = payload;
    const role = fromKeycloakRole(extractRole(payload.roles));
    const userId = extractUserId(role, attributes);
    enableAnalytics(role);
    return {
      userId,
      username,
      email,
      firstName,
      lastName,
      role,
    };
  }

  @Mutation
  public resetProfile() {
    this.userId = "";
    this.username = "";
    this.email = "";
    this.firstName = null;
    this.lastName = "";
    this.role = "guest";
  }

  @Action({ commit: "setAccountPermissions", rawError: true })
  public async getAccountPermissions() {
    let accountPermissions: AccountPermission[] | null = null;
    if (this.role === "customer") {
      accountPermissions = await access_control.users.contacts
        .contacts()
        .then((response) => response.data)
        .then((users) =>
          users.map(
            (user) =>
              ({
                accountId: user.account.id,
                permissions: user.roles,
              } as AccountPermission)
          )
        )
        .catch(() => {
          throw new AccountPermissionError();
        });
    }
    return accountPermissions;
  }

  @Mutation
  setAccountPermissions(accountPermissions: AccountPermission[] | null) {
    this.accountPermissions = accountPermissions;
  }

  @MutationAction({
    mutate: ["token", "refreshToken"],
  })
  public async updateToken(payload: { token: string; refreshToken: string }) {
    return payload;
  }

  @Action({ commit: "SET_TOKEN" })
  public logout() {
    return "";
  }

  @MutationAction({ mutate: ["profileState"], rawError: true })
  private async defineAbilities(profileState: ProfileState) {
    const vue = await import("vue").then((x) => x.default);
    const user = this.state as User;
    User.defineAbilities(
      vue.prototype.$ability,
      user.role || "guest",
      user.userId,
      user.accountPermissions || []
    );
    return { profileState };
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_REFRESHTOKEN(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  get authenticated(): boolean {
    return this.refreshToken !== null && this.token !== null;
  }

  get loggedIn(): boolean {
    return (
      this.authenticated &&
      this.role !== "guest" &&
      (this.profileState === "loaded" ||
        this.profileState === "loadedWithErrors")
    );
  }

  get loggingIn(): boolean {
    return this.profileState === "unloaded";
  }

  get profileLoadingState(): ProfileState {
    return this.profileState;
  }

  get isEmployee(): boolean {
    return this.role === "employee";
  }

  get isCustomer(): boolean {
    return this.role === "customer";
  }

  public static defineAbilities(
    ability: AppAbility,
    role: AppRole,
    userId: string,
    permissions: AccountPermission[] = []
  ): void {
    const rules = buildAbilityFor(role, userId, permissions).rules;
    ability.update(rules);
  }
}

export default User;
