import { Document } from "@/models/document";
import { ParticipantType } from "@/models/participant";
import { filter, intersectionWith, isMatch, iteratee } from "lodash";

// move this logic to the backend.
export const employeeMyTaskFilter = (
  documents: Document[],
  participantId: string,
  participantType: ParticipantType | undefined
) => {
  return documents.filter(
    (document) =>
      (document.state === "READY_FOR_SENDING" &&
        filter(
          document.participants,
          iteratee({ participantId, participantType, role: "sender" })
        ).length > 0) ||
      (document.state === "IN_DRAFT" &&
        filter(
          document.participants,
          iteratee({ participantId, participantType, role: "editor" })
        ).length > 0) ||
      ((document.state === "SENT" ||
        document.state === "SBR_SIGNING_REQUIRED") &&
        filter(
          document.participants,
          iteratee({ participantId, participantType, role: "signer" })
        ).length > 0) ||
      ((document.state === "INITIAL" ||
        document.state === "DRAFT_COMPLETE" ||
        document.state === "ADOPTION_REJECTED" ||
        document.state === "SIGNING_COMPLETED" ||
        document.state === "SIGNABLE_DOCUMENTS_CREATED" ||
        document.state === "SIGNABLE_DOCUMENTS_CREATEPDF_ERR" ||
        document.state === "DECLINED" ||
        document.state === "SBR_SEND_ERROR") &&
        intersectionWith(
          document.participants,
          [
            {
              participantId,
              participantType,
              role: "owner",
            },
            { participantId, participantType, role: "signer" },
          ],
          isMatch
        ).length > 0)
  );
};
