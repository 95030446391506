import { DocumentScope, DocumentStatus } from "@/models/document";
import { Role } from "@/models/role";

export const taskCategories = ["my", "team", "active", "customer"] as const;
export type TaskCategory = typeof taskCategories[number];

export interface TaskFilter {
  scope: DocumentScope;
  documentStatus: DocumentStatus[];
}

export const taskFilter = (taskType: TaskCategory, role: Role): TaskFilter => {
  if (role === "customer") return clientMyTask(filterForTask("customer"));
  return filterForTask(taskType);
};

export const clientMyTask = (filter: TaskFilter) => {
  filter.scope = DocumentScope.SELF;
  return filter;
};
export const filterForTask = (taskType: TaskCategory): TaskFilter => {
  const mapping: Record<TaskCategory, TaskFilter> = {
    my: {
      documentStatus: [
        "SBR_SIGNING_REQUIRED",
        "INITIAL",
        "IN_DRAFT",
        "SIGNABLE_DOCUMENTS_CREATEPDF_ERR",
        "SENT",
        "READY_FOR_SENDING",
        "DECLINED",
        "SBR_SEND_ERROR",
        "ADOPTION_REJECTED",
      ],
      scope: DocumentScope.SELF,
    },
    team: {
      documentStatus: [
        "SBR_SIGNING_REQUIRED",
        "INITIAL",
        "IN_DRAFT",
        "SIGNABLE_DOCUMENTS_CREATEPDF_ERR",
        "SENT",
        "READY_FOR_SENDING",
        "DECLINED",
        "SBR_SEND_ERROR",
        "ADOPTION_REJECTED",
      ],
      scope: DocumentScope.ALL,
    },
    active: {
      documentStatus: [
        "INITIAL",
        "SBR_SIGNING_REQUIRED",
        "IN_DRAFT",
        "DRAFT_COMPLETE",
        "SENT",
        "READY_FOR_SENDING",
        "OWNER_SIGNED",
        "SIGNING_COMPLETED",
        "DECLINED",
        "ADOPTION_REQUIRED",
        "ADOPTION_ACCEPTED",
        "ADOPTION_REJECTED",
        "SIGNABLE_DOCUMENTS_CREATED",
        "SIGNABLE_DOCUMENTS_CREATEPDF_ERR",
        "SBR_SENT",
        "SBR_SEND_ERROR",
        "SBR_COMPLETE",
        "ARCHIVING",
      ],
      scope: DocumentScope.ALL,
    },
    customer: {
      scope: DocumentScope.ALL,
      documentStatus: ["OWNER_SIGNED", "ADOPTION_REQUIRED"],
    },
  };
  return mapping[taskType];
};
