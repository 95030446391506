







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Loader extends Vue {
  @Prop({ default: true }) private dataLoading!: boolean;

  get isLoading() {
    return this.dataLoading;
  }
}
