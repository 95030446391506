













import TheDashboard from "@/components/TheDashboard.vue";
import { Component, Vue } from "vue-property-decorator";
import { LAYOUT_MUTATIONS } from "@/store/modules/layout";
import TheHeader from "@/components/TheHeader.vue";
import Greeter from "@/components/Greeter.vue";
import { namespace } from "vuex-class";

const user = namespace("user");

@Component({
  components: { TheHeader, Greeter, TheDashboard },
})
export default class Home extends Vue {
  @user.State("firstName")
  private name!: string;

  mounted(): void {
    this.$store.commit(LAYOUT_MUTATIONS.setNavigationVisibility, true);
  }
}
