import Vue from "vue";
import App from "./App.vue";
import Component from "vue-class-component";
import store from "./store";
import router from "./router";
import Snotify, { SnotifyPosition } from "vue-snotify";
import { keycloakConfig } from "@/config/keycloak";
import VModal from "vue-js-modal";
import { abilitiesPlugin, Can } from "@casl/vue";
import { buildAbilityFor } from "@/lib/abilities/abilities";
import {
  KeycloakInitConfig,
  KeycloakWrapperInstance,
} from "@/lib/keycloak/KeycloakPluginTypes";
import { KeycloakPlugin } from "@/lib/keycloak/KeycloakPlugin";
import i18n from "./i18n";
import { SnotifyService } from "vue-snotify/SnotifyService";
import Rollbar from "rollbar";
import config from "../rollbar.config";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

const ability = buildAbilityFor("guest", "", []);
const snotifyOptions = {
  toast: {
    position: SnotifyPosition.rightTop,
    showProgressBar: false,
    titleMaxLength: 150,
    timeout: 4000,
    animation: { enter: "fadeIn", exit: "fadeOut", time: 200 },
  },
};
Vue.config.productionTip = process.env.NODE_ENV !== "production";
Vue.config.devtools = process.env.NODE_ENV !== "production";

declare module "vue/types/vue" {
  interface Vue {
    $snotify: SnotifyService | any;
    $keycloak: KeycloakWrapperInstance;
    $rollbar: Rollbar;
  }
}

Vue.prototype.$rollbar = new Rollbar(config);

Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

const keycloakInitConfig: KeycloakInitConfig = {
  store,
  keycloakConfig,
};

Vue.use(Snotify, snotifyOptions);
Vue.use(VModal);
Vue.use(abilitiesPlugin, ability);
Vue.use(KeycloakPlugin, keycloakInitConfig);
Vue.component("Can", Can);

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
