
























































import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Role } from "@/models/role";
import TaskHeader from "@/components/tasks/TaskHeader.vue";
import TaskList from "@/components/tasks/TaskList.vue";
import Tasks from "@/components/tasks/Tasks.vue";

@Component({
  components: {
    TaskHeader,
    TaskList,
    Tasks,
  },
})
@Translatable("dashboard")
export default class DashboardEmployee extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  private role: Role = "employee";
}
