import axios, { AxiosInstance, AxiosResponse } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import QueryString from "qs";
import { DocumentScope, DocumentStatus } from "@/models/document";
import { NewDocument } from "@/store/modules/documents";
import { serialize } from "object-to-formdata";
import { Sort } from "@/api/pagination";
import { problemInterceptor } from "@/api/portal/taskProblemInterceptor";

export interface SigningLink {
  signingLink: string;
}

export interface AdoptionStatus {
  accept: boolean;
  date: string | null;
}

export interface DocumentParameters extends Sort {
  includeAttachments?: string;
  documentType?: string;
  status?: DocumentStatus[];
  scope?: DocumentScope;
}

export const portalClient = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  axiosInstance.interceptors.response.use((r) => r, problemInterceptor);
  return axiosInstance;
};

export const tasksApi = {
  signingLink(taskId: string): Promise<AxiosResponse<SigningLink>> {
    return portalClient().get(`/api/documents/${taskId}/signinglink`);
  },
  addDocument(document: NewDocument): Promise<AxiosResponse> {
    return portalClient().post("/api/documents", serialize(document), {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  delete(
    taskId: string,
    description: string,
    recipient: string | null
  ): Promise<AxiosResponse> {
    return portalClient().delete(`/api/documents/${taskId}`, {
      paramsSerializer: this.escapeParameters,
      params: {
        description: description,
        recipient: recipient,
      },
    });
  },
  updateStatus(
    taskId: string,
    state: DocumentStatus,
    description: string | null
  ): Promise<AxiosResponse> {
    return portalClient().put(`/api/documents/${taskId}/state`, {
      state,
      description,
    });
  },
  putMailmessageForOwnerSigned(
    taskId: string,
    description: string
  ): Promise<AxiosResponse> {
    return portalClient().put(
      `/api/documents/${taskId}/mailmessage/ownerSigned`,
      {
        description,
      }
    );
  },
  getMailmessageForOwnerSigned(
    taskId: string
  ): Promise<AxiosResponse<{ description: string }>> {
    return portalClient().get(
      `/api/documents/${taskId}/mailmessage/ownerSigned`
    );
  },
  adoptDocument(
    taskId: string,
    accept: boolean,
    date?: string | null
  ): Promise<AxiosResponse> {
    return portalClient().post(`/api/documents/${taskId}/adoptiondate`, {
      accept,
      date,
    });
  },
  getAdoptionStatus(taskId: string): Promise<AxiosResponse<AdoptionStatus>> {
    return portalClient().get(`/api/documents/${taskId}/adoptiondate`);
  },

  escapeParameters(params: Record<string, any>): string {
    return QueryString.stringify(params);
  },
};
