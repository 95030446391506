




































import { Component, ModelSync, Prop, Vue, Watch } from "vue-property-decorator";
import { FormField } from "@/components/form-components/types";

@Component({
  components: {},
  inheritAttrs: false,
})
export default class FormInputField extends Vue implements FormField {
  /**
   * Things like inputmode, pattern, placeholder, minlength, maxlength, required, etc.
   * Are bound 1-on-1 through v-bind="$attrs"
   */

  @Prop({ default: "" }) public label!: string;

  @Prop({ default: false }) public required!: boolean;

  @Prop({ default: undefined, type: Number }) public maxLength!:
    | number
    | undefined;

  @ModelSync("value", "input") valueField!: string;

  $refs!: {
    input: HTMLInputElement;
  };

  public isValidatedOnce = false;
  public autoFilled = true;
  protected focused: boolean = false;

  get showMaxLengthWarning(): boolean {
    if (!this.maxLength) return false;
    return this.charactersLeft <= Math.ceil(this.maxLength * 0.25);
  }

  get charactersLeft(): number {
    if (!this.maxLength) return 0;
    return (this.maxLength || 0) - this.valueField.length;
  }

  get type() {
    return this.$attrs.type || "text";
  }

  get filled() {
    const value = this.$refs.input.value || "";
    return this.focused || value.length > 0;
  }

  public validate() {
    this.isValidatedOnce = true;
    return this.isValid();
  }

  @Watch("valueField")
  protected onInput() {
    this.autoFilled = false;
    this.isValidatedOnce = false;
  }

  protected isValid() {
    return this.isValidatedOnce ? this.checkValidity() : true;
  }

  protected getValidity() {
    const input = this.$refs.input;
    return input ? input.validity : {};
  }

  mounted() {
    // Workaround to get the label out of the field it's autofilled.
    // We're assuming it's autofilled until we know that it isn't.
    document.addEventListener("DOMContentLoaded", (evt) => {
      setTimeout(() => {
        this.autoFilled = this.isAutoFilled();
      }, 500);
    });
    setTimeout(() => {
      this.autoFilled = this.isAutoFilled();
    }, 500);
  }

  private isAutoFilled() {
    return (
      this.$el && this.$el.querySelectorAll("input:-webkit-autofill").length > 0
    );
  }

  private checkValidity() {
    return this.$refs.input ? this.$refs.input.checkValidity() : false;
  }
}
