import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { Document, DocumentType } from "@/models/document";
import { Role } from "@/models/role";
import { AccountPermission } from "@/store/modules/user";

type Actions =
  | "create"
  | "view"
  | "start"
  | "finish"
  | "edit"
  | "update"
  | "revoke"
  | "resubmit"
  | "reject"
  | "take_over"
  | "adopt"
  | "sign"
  | "sign_sbr"
  | "send"
  | "finish_failed_sbr";

type Subjects =
  | "Contact"
  | "Dashboard"
  | "EmployeeTask"
  | "CustomerTask"
  | "MyTeamTasks"
  | "MyActiveTasks"
  | "Document"
  | "UserManagement"
  | "Dossier"
  | "SBRDocument"
  | "DocumentStatus"
  | Document;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
export type AppRole = Role | "guest";
type DefinePermissions = (
  user: PortalUser,
  builder: AbilityBuilder<AppAbility>
) => void;

export interface PortalUser {
  role: AppRole;
  userId: string;
  permissions: AccountPermission[];
}

const rolePermissions: Record<AppRole, DefinePermissions> = {
  customer(user, { can }) {
    const userPermissions = user.permissions.flatMap((x) => x.permissions);
    can("view", "Dashboard");
    can("view", "CustomerTask");
    can("view", "Contact");

    if (userPermissions.includes("dossier")) {
      can("view", "Dossier");
    }
    if (userPermissions.includes("user_management")) {
      can("view", "UserManagement");
    }

    can("view", "Document", {
      state: { $in: ["OWNER_SIGNED"] }, // uitbreiden met status..
    });

    can("reject", "Document", {
      state: {
        $in: ["ADOPTION_REQUIRED", "OWNER_SIGNED"],
      },
    });

    can("sign", "Document", "customer", {
      state: { $in: ["OWNER_SIGNED"] },
      participants: {
        $elemMatch: {
          role: "signer",
          participantId: user.userId,
          participantType: "CLIENT",
        },
      },
    });

    can("adopt", "Document", {
      state: {
        $in: ["ADOPTION_REQUIRED", "SENT"],
      },
      documentType: {
        $in: [
          "jaarrekening-klein",
          "jaarrekening-micro",
          "jaarrekening-middelgroot",
          "jaarrekening-groot",
        ] as DocumentType[],
      },
    });
  },
  employee(user, { can, cannot }) {
    can("view", "Dashboard");
    can("view", "UserManagement");
    can("view", "EmployeeTask");
    can("view", "CustomerTask");
    can("view", "MyTeamTasks");
    can("view", "MyActiveTasks");
    can("view", "Dossier");
    can("create", "Document");
    can("revoke", "Document", {
      state: {
        $in: [
          "IN_DRAFT",
          "INITIAL",
          "SBR_SIGNING_REQUIRED",
          "ADOPTION_REQUIRED",
          "SENT",
          "READY_FOR_SENDING",
          "DECLINED",
          "SIGNABLE_DOCUMENTS_CREATED",
          "SIGNABLE_DOCUMENTS_CREATEPDF_ERR",
        ],
      },
    });
    can("revoke", "Document", {
      state: {
        $in: ["OWNER_SIGNED"],
      },
    });
    can("take_over", "Document", {
      state: { $in: ["SENT"] },
      documentType: {
        $in: [
          "omzetbelasting",
          "omzetbelasting-suppletie",
          "icp",
        ] as DocumentType[],
      },
    });
    cannot("take_over", "Document", {
      state: { $in: ["SENT"] },
      documentType: {
        $in: [
          "omzetbelasting",
          "omzetbelasting-suppletie",
          "icp",
        ] as DocumentType[],
      },
      participants: {
        $elemMatch: {
          role: "signer",
          participantId: user.userId,
          participantType: "EMPLOYEE",
        },
      },
    });
    /**
     * For the given types, only the client signer needs to sign. Therefore, no employee signer has been
     * associated with this document. In order to revoke documents of these types, the owner is allowed
     * to revoke the document up until the client has signed.
     */
    can("revoke", "Document", {
      state: {
        $in: ["OWNER_SIGNED"],
      },
      documentType: {
        $in: [
          "vennootschapsbelasting",
          "inkomstenbelasting",
          "inkomstenbelasting-particulier",
          "inkomstenbelasting-winst",
        ] as DocumentType[],
      },
      participants: {
        $elemMatch: {
          role: "owner",
          participantId: user.userId,
          participantType: "EMPLOYEE",
        },
      },
    });

    can("sign", "Document", "employee", {
      state: { $in: ["SENT"] },
      documentType: {
        $nin: [
          "jaarrekening-middelgroot",
          "jaarrekening-groot",
          "concept-jaarrekening",
        ] as DocumentType[],
      },
      participants: {
        $elemMatch: {
          role: "signer",
          participantId: user.userId,
          participantType: "EMPLOYEE",
        },
      },
    });

    can("send", "Document", "employee", {
      state: { $in: ["READY_FOR_SENDING"] },
      participants: {
        $elemMatch: {
          role: "sender",
          participantId: user.userId,
          participantType: "EMPLOYEE",
        },
      },
      documentType: {
        $in: ["concept-jaarrekening"] as DocumentType[],
      },
    });

    can("sign_sbr", "Document", {
      state: { $in: ["SBR_SIGNING_REQUIRED"] },
      documentType: {
        $in: [
          "jaarrekening-middelgroot",
          "jaarrekening-groot",
        ] as DocumentType[],
      },
    });

    can("finish_failed_sbr", "Document", {
      state: { $in: ["SBR_SEND_ERROR"] },
      documentType: {
        $in: [
          "vennootschapsbelasting",
          "inkomstenbelasting",
          "jaarrekening-klein",
          "jaarrekening-micro",
          "jaarrekening-middelgroot",
          "jaarrekening-groot",
          "kredietrapportage-beperkt",
          "kredietrapportage-middelgroot",
          "kredietrapportage-persoon",
          "omzetbelasting",
          "omzetbelasting-suppletie",
          "icp",
        ] as DocumentType[],
      },
    });

    can("start", "Document", "omzetbelasting", {
      documentType: {
        $in: ["omzetbelasting", "omzetbelasting-suppletie"] as DocumentType[],
      },
      state: { $in: ["IN_DRAFT"] },
    });

    can("finish", "Document", "omzetbelasting", {
      documentType: {
        $in: ["omzetbelasting", "omzetbelasting-suppletie"] as DocumentType[],
      },
      state: { $in: ["IN_DRAFT"] },
    });

    can("finish", "Document", "icp", {
      documentType: {
        $in: ["icp"] as DocumentType[],
      },
      state: { $in: ["IN_DRAFT"] },
    });

    can("resubmit", "Document", {
      state: { $in: ["DECLINED"] },
    }); // als een klant deze heeft afgewezen.
  },
  guest(user, { can }) {},
};

export function buildAbility(user: PortalUser): AppAbility {
  return buildAbilityFor(user.role, user.userId, user.permissions);
}

export function buildAbilityFor(
  role: AppRole,
  userId: string,
  permissions: AccountPermission[]
): AppAbility {
  const builder = new AbilityBuilder(AppAbility);
  if (typeof rolePermissions[role] === "function") {
    rolePermissions[role]({ role, userId, permissions }, builder);
  } else {
    throw new Error("Trying to use unknown role");
  }
  return builder.build();
}
