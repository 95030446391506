





























































































































import HamburgToggle from "@/components/HamburgToggle.vue";
import NavigationItem from "@/components/NavigationItem.vue";
import ResponsiveLogo from "@/components/ResponsiveLogo.vue";
import { NavigationEntry } from "@/interfaces/NavigationEntry";
import { LAYOUT_ACTIONS } from "@/store/modules/layout";
import * as ScrollMagic from "scrollmagic";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Role } from "@/models/role";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

const user = namespace("user");
const layout = namespace("layout");
@Component({
  components: {
    HamburgToggle,
    NavigationItem,
    ResponsiveLogo,
  },
})
@Translatable("")
export default class TheNavigation extends mixins(TranslatableComponent) {
  private static hiddenClass: string = "nav--hidden";
  private controller: any;
  private isShowKnowledgeBase: boolean = false;

  @user.Getter("isEmployee")
  private isEmployee!: boolean;

  @user.State("role")
  private role!: Role;

  @layout.Getter("minimal")
  private minimal!: boolean;

  get customerTasks(): NavigationEntry {
    return this.isEmployee
      ? {
          title: `${this.translated_component_value(
            "employee.customer_tasks"
          )}`,
          link: `/taken?category=customer`,
          icon: "icon--clipboard-blank",
        }
      : {
          title: `${this.translated_component_value(
            "customer.customer_tasks"
          )}`,
          link: `/taken`,
          icon: "icon--clipboard-user",
        };
  }

  public toggleNavigation(): void {
    this.$store.dispatch(LAYOUT_ACTIONS.toggleMinimal);
  }

  public toggleMobileNavigation(): void {
    document.querySelector("nav")?.classList.toggle("is-open");
  }

  private destroyed() {
    if (this.controller) {
      this.controller.destroy();
    }
  }

  get logout(): string {
    const redirectUri =
      this.role === "employee"
        ? process.env.VUE_APP_LOGOUT_URL_EMPLOYEE
        : process.env.VUE_APP_LOGOUT_URL_CUSTOMER;
    return this.$keycloak.createLogoutUrl({
      redirectUri,
    });
  }

  mounted() {
    this.isShowKnowledgeBase =
      process.env.VUE_APP_SHOW_KNOWLEDGE_BASE?.toLowerCase() === "true";

    const navigation = this.$el as HTMLElement;
    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: { duration: 100 },
    });

    const scene = new ScrollMagic.Scene()
      .on("update", (e: any) => {
        const direction = e.target.controller().info("scrollDirection");
        const pos = e.target.controller().info("scrollPos");
        const size = navigation.clientHeight * 2;
        if (pos < size) {
          navigation.classList.remove(TheNavigation.hiddenClass);
          return;
        }
        if (direction === "FORWARD") {
          navigation.classList.add(TheNavigation.hiddenClass);
        } else {
          navigation.classList.remove(TheNavigation.hiddenClass);
        }
      })
      .addTo(this.controller);
  }
}
