










import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Role } from "@/models/role";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import DashboardLoading from "@/components/dashboard/DashboardLoading.vue";
import DashboardEmployee from "@/components/dashboard/DashboardEmployee.vue";
import DashboardCustomer from "@/components/dashboard/DashboardCustomer.vue";

const user = namespace("user");
@Component({
  components: {
    DashboardLoading,
    DashboardEmployee,
    DashboardCustomer,
  },
})
@Translatable("")
export default class TheDashboard extends mixins(TranslatableComponent) {
  @user.State("role")
  private role!: Role;

  get dashboardComponent():
    | "dashboard-customer"
    | "dashboard-employee"
    | "dashboard-loading" {
    const components: Record<
      Role,
      "dashboard-customer" | "dashboard-employee" | "dashboard-loading"
    > = {
      employee: "dashboard-employee",
      customer: "dashboard-customer",
    };
    return components[this.role] || "dashboard-loading";
  }
}
