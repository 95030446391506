export const assertFulfilled = <T>(
  item: PromiseSettledResult<T>
): item is PromiseFulfilledResult<T> => {
  return item.status === "fulfilled";
};

export const assertRejected = <T>(
  item: PromiseSettledResult<T>
): item is PromiseRejectedResult => {
  return item.status === "rejected";
};

export function getValue<T>(fulFilledPromise: PromiseFulfilledResult<T[]>) {
  return fulFilledPromise.value;
}

export function resolvePromiseSettledResult<T>(
  results: PromiseSettledResult<T[]>[]
): T[] {
  return results.filter(assertFulfilled).map(getValue).flat();
}
