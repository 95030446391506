



import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component, Prop } from "vue-property-decorator";
import { formatIsoDate } from "@/filters";
import { mixins } from "vue-class-component";
import { getHours } from "date-fns";

@Component({
  methods: { formatIsoDate },
})
@Translatable("")
export default class Greeter extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private name!: string;

  @Prop()
  private date!: Date;

  get greetTime(): "morning" | "afternoon" | "evening" {
    let hours = getHours(this.date);
    if (hours < 12) {
      return "morning";
    } else if (hours < 18) {
      return "afternoon";
    } else {
      return "evening";
    }
  }
}
