























import { Component, Prop, Vue } from "vue-property-decorator";
import TaskList from "@/components/tasks/TaskList.vue";
import TaskHeader from "@/components/tasks/TaskHeader.vue";
import { namespace } from "vuex-class";
import { TaskCategory } from "@/models/task";
import { Role } from "@/models/role";

const layout = namespace("layout");

@Component({
  components: { TaskHeader, TaskList },
})
export default class Tasks extends Vue {
  private tasksCount: number = 0;

  @Prop() private taskCategory!: TaskCategory;
  @Prop() private title!: string;
  @Prop() private noTasksText!: string;
  @Prop() private linkText?: string;
  @Prop() private role!: Role;
  @Prop() private buttonClass!: string;

  private updateTaskCount(count: number) {
    this.tasksCount = count;
  }

  get linkDestination() {
    return { name: "documents", query: { category: this.taskCategory } };
  }
}
