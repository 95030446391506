
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ScalingImage extends Vue {
  @Prop()
  private image!: string;

  private getImagePath(image: string, size: string): void {
    try {
      return require(`@/assets/images/${size}/${image}`);
    } catch (err) {
      return require(`@/assets/images/${size}/default.jpg`);
    }
  }
}
