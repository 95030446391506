






import VLogo from "@/components/VLogo.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { VLogo: VLogo },
})
export default class ResponsiveLogo extends Vue {}
