import { AxiosError } from "axios/index";

export interface Problem {
  type: string;
  status: number;
  title: string;
  detail: string;
}

export class DefaultProblem<T extends Problem, D = any> extends AxiosError {
  problem: Problem | undefined;

  constructor(error?: AxiosError<T, D>) {
    super(
      error?.message,
      error?.code,
      error?.config,
      error?.request,
      error?.response
    );

    this.problem = error?.response?.data;
  }
}
