export type Discipline =
  | "accountancy"
  | "accountantscontrole"
  | "belastingadvies"
  | "personeel"
  | "corporate_finance"
  | "familieadvies"
  | "it_advies"
  | "subsidieadvies";

export const resolveDiscipline = (discipline: Discipline): string | null => {
  const mapping = JSON.parse(process.env.VUE_APP_DISCIPLINES || "{}") as Record<
    Discipline,
    string
  >;
  return mapping[discipline];
};
