import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Account } from "@/interfaces/Account";
import { Employee, IEmployee } from "@/models/employee";
import {
  ApiUserManagementUser,
  Profile,
  CreatedCustomer,
  Customer,
  NewContact,
  NewCustomer,
  UserManagementUser,
  AuthInfo,
} from "@/models/user";
import { Permission, Role } from "@/models/role";
import { injectBearerToken, requestReject } from "@/api/axios";
import { DocumentType } from "@/models/document";

interface RolesEndpoint {
  update: (
    contactPersonId: string,
    roles: Permission[]
  ) => Promise<AxiosResponse<Role[]>>;
}

interface AccountEndpoint {
  account: (accountId: string) => Promise<AxiosResponse<Account>>;
  accounts: () => Promise<AxiosResponse<Account[]>>;
  accountsWithRole: (role: Permission) => Promise<AxiosResponse<Account[]>>;
  accountEmployeesSigner: (
    accountId: string,
    type: DocumentType
  ) => Promise<AxiosResponse<Employee[]>>;
  accountEmployees: (
    accountId: string,
    params?: { authorizedToSign?: boolean }
  ) => Promise<AxiosResponse<Employee[]>>;
}

interface ContactPersonsEndpoint {
  contacts: () => Promise<AxiosResponse<UserManagementUser[]>>;
  roles: RolesEndpoint;
}

interface UsersEndpoints {
  contacts: ContactPersonsEndpoint;
}

interface AccountUsersEndpoint {
  index: (accountId: string) => Promise<AxiosResponse<UserManagementUser[]>>;
  get: (
    accountId: string,
    profileId: string
  ) => Promise<AxiosResponse<UserManagementUser>>;
}

interface UserManagementContactsEndpoint {
  contacts: () => Promise<AxiosResponse<UserManagementUser[]>>;
  contact: (contactId: string) => Promise<AxiosResponse<UserManagementUser>>;
  create: (newContact: NewContact) => Promise<AxiosResponse<Profile>>;
  delete: (contactId: string) => Promise<AxiosResponse<Profile>>;
}

interface AuthsEndpoint {
  index: (userId: string) => Promise<AxiosResponse<AuthInfo>>;
  reset_otp: (userId: string) => Promise<AxiosResponse<void>>;
  delete: (userId: string) => Promise<AxiosResponse<void>>;
}

interface UserManagementEndpoints {
  users: () => Promise<AxiosResponse<Customer[]>>;
  create: (newUser: NewCustomer) => Promise<AxiosResponse<CreatedCustomer>>;
  contacts: UserManagementContactsEndpoint;
  auths: AuthsEndpoint;
}

export interface AccessControlApi {
  accounts: AccountEndpoint;
  users: UsersEndpoints;
  usermanagement: UserManagementEndpoints;
  account_users: AccountUsersEndpoint;
}

export const accessControlApi = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ACCESS_CONTROL_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

const api: AccessControlApi = {
  accounts: {
    account(accountId) {
      return accessControlApi().get(`/accounts/${accountId}`);
    },
    accounts() {
      return accessControlApi().get(`/accounts`);
    },
    accountsWithRole(ability: Permission) {
      return accessControlApi().get(`/accounts?role=${ability}`);
    },
    accountEmployeesSigner(accountId, documentType) {
      return accessControlApi().get(
        `/accounts/${accountId}/documentTypes/${documentType}/employees`,
        {
          transformResponse: [
            (data: string) => {
              return JSON.parse(data).map((entry: IEmployee) =>
                Employee.fromIEmployee(entry)
              );
            },
          ],
        }
      );
    },
    accountEmployees(accountId, params?) {
      return accessControlApi().get(`/accounts/${accountId}/employees`, {
        params,
        transformResponse: [
          (data: string) => {
            return JSON.parse(data).map((entry: IEmployee) =>
              Employee.fromIEmployee(entry)
            );
          },
        ],
      });
    },
  },
  usermanagement: {
    users() {
      return accessControlApi().get("/usermanagement");
    },
    create(newUser: NewCustomer) {
      return accessControlApi().post("/usermanagement", newUser);
    },

    contacts: {
      contacts() {
        return accessControlApi().get("/usermanagement/profiles", {
          transformResponse: [
            (data: string) => {
              return JSON.parse(data).map((entry: ApiUserManagementUser) =>
                UserManagementUser.fromApiUserManagementUser(entry)
              );
            },
          ],
        });
      },
      contact(contactId: string) {
        return accessControlApi().get(`/usermanagement/profiles/${contactId}`, {
          transformResponse: [
            (data: string) =>
              UserManagementUser.fromApiUserManagementUser(JSON.parse(data)),
          ],
        });
      },
      create(newContact: NewContact) {
        return accessControlApi().post("/usermanagement/profiles", newContact);
      },
      delete(contactId: string) {
        return accessControlApi().delete(
          `/usermanagement/profiles/${contactId}`
        );
      },
    },
    auths: {
      index(userId: string) {
        return accessControlApi().get(`/usermanagement/${userId}/auths`);
      },
      delete(userId: string) {
        return accessControlApi().delete(`/usermanagement/${userId}/auths`);
      },
      reset_otp(userId: string) {
        return accessControlApi().delete(
          `/usermanagement/${userId}/auths/credentials`
        );
      },
    },
  },
  users: {
    contacts: {
      contacts() {
        return accessControlApi().get("/users/contacts");
      },
      roles: {
        update(contactPersonId: string, roles: Permission[]) {
          return accessControlApi().put(
            `/users/contacts/${contactPersonId}/roles`,
            { roles }
          );
        },
      },
    },
  },
  account_users: {
    index(accountId: string) {
      return accessControlApi().get(`/accounts/${accountId}/profiles/`, {
        transformResponse: [
          (data: string) => {
            return JSON.parse(data).map((entry: ApiUserManagementUser) =>
              UserManagementUser.fromApiUserManagementUser(entry)
            );
          },
        ],
      });
    },
    get(accountId: string, profileId: string) {
      return accessControlApi().get(
        `/accounts/${accountId}/profiles/${profileId}`,
        {
          transformResponse: [
            (data: string) => {
              return UserManagementUser.fromApiUserManagementUser(
                JSON.parse(data)
              );
            },
          ],
        }
      );
    },
  },
};

export default api;
