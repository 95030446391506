import axios, { AxiosInstance, AxiosResponse } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import { problemInterceptor } from "@/api/portal/taskProblemInterceptor";
import { Document, IDocument } from "@/models/document";
import QueryString from "qs";

export const taskReadApiClient = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_TASK_READ_API_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  axiosInstance.interceptors.response.use((r) => r, problemInterceptor);
  return axiosInstance;
};

export const taskReadApi = {
  tasks(params = {}): Promise<AxiosResponse<Document[]>> {
    return taskReadApiClient().get(`/tasks`, {
      params,
      paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: "repeat" }),
      transformResponse: [
        (data: string) =>
          JSON.parse(data).map((entry: IDocument) =>
            Document.fromDocument(entry)
          ),
      ],
    });
  },
  task(taskId: string): Promise<AxiosResponse<Document>> {
    return taskReadApiClient().get(`/tasks/${taskId}`, {
      transformResponse: [
        (data: string) => Document.fromDocument(JSON.parse(data)),
      ],
    });
  },
};
