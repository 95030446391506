import { format, parse, parseISO } from "date-fns";
import { nl } from "date-fns/locale";

export function formatIsoDate(dateString: string) {
  const parsed = parse(dateString, "yyyy-MM-dd", new Date());
  return format(parsed, "dd-MM-yyyy");
}

export function formatIsoDateTime(isoDateTimeWithZone: string) {
  return format(parseISO(isoDateTimeWithZone), "eee dd MMM yyyy kk:mm:ss", {
    locale: nl,
  });
}

export function formatIsoDateTimeToDate(isoDateTimeWithZone: string) {
  return format(parseISO(isoDateTimeWithZone), "YYY-MM-dd", {
    locale: nl,
  });
}

export function formatDateToDateString(date: Date) {
  return format(date, "dd-MM-YYY", {
    locale: nl,
  });
}

export function formatTimestampToIsoDate(
  timestamp: number
): string | undefined {
  if (!timestamp) return;
  return format(new Date(timestamp), "dd-MM-YYY", {
    locale: nl,
  });
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
