









import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import Loader from "@/components/Loader.vue";

@Component({
  components: {
    Loader,
  },
})
@Translatable("dashboard")
export default class DashboardLoading extends mixins<TranslatableComponent>(
  TranslatableComponent
) {}
