import { KeyCloakRole } from "@/models/keycloak";

export const permissions = [
  "user_management",
  "dossier",
  "documents_other_read",
  "documents_other_approval",
  "documents_other_mandatory_approval",
  "documents_financial_read",
  "documents_financial_approval",
  "documents_financial_mandatory_approval",
  "documents_fiscal_read",
  "documents_fiscal_approval",
  "documents_fiscal_mandatory_approval",
] as const;

export type Permission = typeof permissions[number];

export type Right = "read" | "approval" | "mandatory_approval";

export const roles = ["employee", "customer"] as const;
export type Role = typeof roles[number];

export const fromKeycloakRole = (role: KeyCloakRole): Role => {
  const converter: Record<KeyCloakRole, Role> = {
    ROLE_EMPLOYEE: "employee",
    ROLE_USER: "customer",
  };
  return converter[role];
};
