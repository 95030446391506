





























import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import BasicModal from "@/components/modals/partials/BasicModal.vue";
import CheckboxField from "@/components/form-components/CheckboxField.vue";
import Cookies from "js-cookie";

@Component({
  components: { CheckboxField, BasicModal },
})
@Translatable("modals")
export default class RedirectWarningModal extends mixins(
  TranslatableComponent
) {
  @Prop() private componentName!: string;

  @Prop() private redirectLink!: string;

  @Prop() private site!: string;

  private isCheckboxChecked: boolean = false;

  private cancel() {
    this.$modal.hide(this.componentName);
  }

  private handleRedirect() {
    if (this.isCheckboxChecked) {
      Cookies.set(
        `hide_knowledge_base_external_link_warning_for_${this.site}`,
        "true"
      );
    }

    window.open(this.redirectLink, "_blank");

    this.$modal.hide(this.componentName);
  }

  get siteName() {
    return this.translated_component_value(this.site);
  }
}
