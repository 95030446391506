var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":{
    name: 'documents-show',
    params: { documentId: _vm.task.id },
    query: {
      category: _vm.taskCategory,
      selectedAccountId: _vm.selectedAccountId,
    },
  },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('div',{staticClass:"task",on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('div',{staticClass:"task-content"},[_c('h3',{staticClass:"task-name"},[_vm._v(_vm._s(_vm.task.name))]),_c('div',{staticClass:"task-account"},[_vm._v(_vm._s(_vm.task.account))]),(_vm.task.endDate)?_c('div',{staticClass:"task-deadline"},[_vm._v(" Deadline: "+_vm._s(_vm.formatIsoDate(_vm.task.endDate))+" ")]):_vm._e(),(_vm.task.state)?_c('div',{staticClass:"task-state"},[_vm._v(_vm._s(_vm.task.state))]):_vm._e()])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }