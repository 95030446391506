

























import { mixins } from "vue-class-component";
import TranslatableComponent from "../lib/mixins/translateable";
import { Component } from "vue-property-decorator";

import Loader from "@/components/Loader.vue";

@Component({
  components: { Loader },
})
export default class TheProfileError extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  get logout(): string {
    return this.$keycloak.createLogoutUrl({
      redirectUri: this.$keycloak.createLoginUrl({
        redirectUri: document.location.href,
      }),
    });
  }
}
