import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { Vue } from "vue-property-decorator";
import { Problem } from "@/api/problem/problem";

export function injectBearerToken<T>(
  config: InternalAxiosRequestConfig<T>
): InternalAxiosRequestConfig<T> {
  // Do something before request is sent
  config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token()}`;
  return config;
}

export const requestReject = <T, D>(
  error: AxiosError<T, D>
): Promise<AxiosError<T, D>> => {
  // Do something with request error
  return Promise.reject(error);
};
