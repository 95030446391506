






























import { Component } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import magnoliaCmsApi, { KnowledgeBaseGetParams } from "@/api/magnoliaCmsApi";
import { AxiosResponse } from "axios";
import {
  KnowledgeBaseArticle,
  KnowledgeBaseData,
} from "@/interfaces/KnowledgeBase";
import KnowledgeBaseItem from "@/components/knowledgeBase/KnowledgeBaseItem.vue";
import Loader from "@/components/Loader.vue";
import { personalisationApi } from "@/api/personalisation";
import { AccountPermission } from "@/store/modules/user";
import { namespace } from "vuex-class";
import { chain } from "lodash";
import { assertFulfilled } from "@/utils/promises";
import { Discipline, resolveDiscipline } from "@/models/disciplines";

const user = namespace("user");
@Component({ components: { KnowledgeBaseItem, Loader } })
export default class SuggestedArticles extends mixins(TranslatableComponent) {
  private data: KnowledgeBaseArticle[] = [];
  private isContentLoading: boolean = true;
  private hasApiError: boolean = false;
  private hasNoContent: boolean = false;

  @user.State("accountPermissions")
  private accounts!: Array<AccountPermission> | null;

  get articles(): KnowledgeBaseArticle[] {
    return this.data;
  }

  mounted() {
    const accounts =
      this.accounts === null || this.accounts.length === 0
        ? [Promise.reject()]
        : this.accounts.map((account) =>
            personalisationApi.disciplines(account.accountId)
          );
    Promise.allSettled(accounts)
      .then(
        async (
          responses: PromiseSettledResult<AxiosResponse<Discipline[]>>[]
        ) => {
          const disciplines = chain(responses)
            .filter(assertFulfilled)
            .map(
              (x: PromiseFulfilledResult<AxiosResponse<Discipline[]>>) =>
                x.value.data
            )
            .flattenDeep()
            .map(resolveDiscipline)
            .uniq()
            .compact()
            .value() as unknown as string[];
          this.data = await this.getKnowledgeBaseData({
            disciplines,
            resultsPerPage: 3,
          });
        }
      )
      .finally(() => {
        this.isContentLoading = false;
      });
  }

  getKnowledgeBaseData(
    knowledgeBaseGetParams: KnowledgeBaseGetParams
  ): Promise<KnowledgeBaseArticle[]> {
    return magnoliaCmsApi
      .getArticles(knowledgeBaseGetParams)
      .then((response: AxiosResponse<KnowledgeBaseData>) => {
        if (!response.data || response.data.hits.length === 0) {
          this.hasNoContent = true;
          return [];
        }
        return response.data.hits;
      })
      .catch(() => {
        this.hasApiError = true;
        return [];
      });
  }
}
