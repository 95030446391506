import routes from "@/router/routes";

import NProgress from "nprogress";
import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import { keycloakInitOptions } from "@/config/keycloak";
import { finishNavigating } from "@/utils/finishNavigating";
import store from "@/store";
import { LAYOUT_MUTATIONS } from "@/store/modules/layout";

Vue.use(VueRouter);

NProgress.configure({ parent: ".layout__main" });

const router: VueRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
  await Vue.prototype.$keycloak.init(keycloakInitOptions, store);

  const loggedIn = Vue.prototype.$keycloak.authenticated();

  to.meta!.public && !loggedIn
    ? store.commit(LAYOUT_MUTATIONS.setNavigationVisibility, false)
    : store.commit(LAYOUT_MUTATIONS.setNavigationVisibility, true);

  const publicPages = routes
    .filter((value) => value.meta && value.meta.public === true)
    .map((value) => value.path);
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !loggedIn) {
    const originUrl = `${window.location.origin}/${to.fullPath}`;
    window.location.replace(
      Vue.prototype.$keycloak.createLoginUrl({
        redirectUri: originUrl,
      })
    );
  } else {
    NProgress.start();
    next();
  }
});

router.afterEach((to, from) => {
  finishNavigating();
});

export default router;
