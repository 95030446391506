




































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import FormInputField from "@/components/form-components/FormInputField.vue";

@Component({
  components: { FormInputField },
})
export default class BasicModal extends Vue {
  @Prop()
  private submitDisabled!: boolean;

  @Emit("action_cancel")
  private cancel() {}

  @Emit("action_ok")
  private submit() {}
}
