





















import { Component, ModelSync, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
  inheritAttrs: false,
})
export default class CheckboxField<T> extends Vue {
  @Prop({ default: "" }) public label!: string;

  @Prop()
  public value!: T;

  @Prop({ default: false, type: Boolean }) required!: boolean;

  @ModelSync("checked", "change")
  public checkedValue!: T;

  protected focused: boolean = false;
}
