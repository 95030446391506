























import { Component, Prop, Vue } from "vue-property-decorator";
import { Route } from "vue-router";

@Component
export default class TaskHeader extends Vue {
  @Prop()
  private title?: string;

  @Prop()
  private linkText?: string;

  @Prop()
  private count?: number;

  @Prop()
  private linkDestination?: Route;

  @Prop()
  private buttonClass!: string;
}
