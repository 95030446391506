var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isRouteLink)?_c('router-link',{attrs:{"to":_vm.item.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-items__item",class:[
      isActive && 'router-link-active',
      isExactActive && 'router-link-exact-active',
      _vm.item.requireStepUp && 'require-step-up',
      _vm.minimal && 'is-minimal' ],on:{"click":function($event){return _vm.closeNav()}}},[_c('a',{attrs:{"href":_vm.item.link},on:{"click":navigate}},[_c('i',{class:_vm.iconName}),_c('span',[_vm._v(_vm._s(_vm.item.title))])])])]}}],null,false,1130742503)}):_c('li',{staticClass:"nav-items__item",class:[_vm.minimal && 'is-minimal']},[_c('a',{staticClass:"external",attrs:{"href":_vm.item.link}},[_c('i',{class:_vm.iconName}),_c('span',[_vm._v(_vm._s(_vm.item.title))])])])}
var staticRenderFns = []

export { render, staticRenderFns }