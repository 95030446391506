














import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent from "@/lib/mixins/translateable";
import { namespace } from "vuex-class";

const user = namespace("user");

@Component
export default class TheZendeskButton extends mixins(TranslatableComponent) {
  @user.Getter
  private loggedIn!: boolean;

  get isShow() {
    return this.loggedIn;
  }
}
