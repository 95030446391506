












































import { Component, Prop } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { KnowledgeBaseArticle } from "@/interfaces/KnowledgeBase";
import Cookies from "js-cookie";
import RedirectWarningModal from "@/components/modals/RedirectWarningModal.vue";
import { capitalizeFirstLetter, formatTimestampToIsoDate } from "@/filters";

@Component({
  components: { RedirectWarningModal },
  methods: { formatTimestampToIsoDate },
})
export default class KnowledgeBaseItem extends TranslatableComponent {
  @Prop() private data!: KnowledgeBaseArticle;

  private fallBackImage: string = require("@/assets/fallbackImage.jpg");

  get imageSrc() {
    return this.data._source.image
      ? process.env.VUE_APP_MAGNOLIA_CMS_URL + this.data._source.image
      : "";
  }

  get tags(): string[] {
    const tags: string[] = [];

    this.data._source.disciplines.forEach((tagKey) => {
      tags.push(this.getFilterLabel(tagKey));
    });

    this.data._source.types.forEach((tagKey) => {
      tags.push(this.getFilterLabel(tagKey));
    });

    return tags;
  }

  getFilterLabel(filterOption: string): string {
    if (this.hasTranslation(`views.knowledge_base.filters.${filterOption}`)) {
      return this.translated_raw_view_value(
        `knowledge_base.filters.${filterOption}`
      ) as string;
    } else {
      return capitalizeFirstLetter(filterOption);
    }
  }

  showDefaultImage(event: Event) {
    (event!.target! as HTMLImageElement).src = this.fallBackImage;
  }

  handleLinkClick(event: Event) {
    if (
      !Cookies.get(
        `hide_knowledge_base_external_link_warning_for_${this.data._source.site}`
      )
    ) {
      event.preventDefault();
      this.$modal.show(this.data._source.id);
    }
  }
}
