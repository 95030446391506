









































import { Component } from "vue-property-decorator";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import { namespace } from "vuex-class";
import ScalingImage from "@/components/ScalingImage.vue";

const user = namespace("user");

@Component({
  components: { ScalingImage },
})
@Translatable("landing")
export default class Landing extends mixins(TranslatableComponent) {
  @user.Getter
  private loggedIn!: boolean;

  mounted() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  }

  private getImagePath(image: string, size: string): void {
    try {
      return require(`@/assets/images/${size}/${image}`);
    } catch (err) {
      return require(`@/assets/headers/${size}/contact.jpg`);
    }
  }

  private loginUrl(): string {
    return this.$keycloak.createLoginUrl({
      redirectUri: `${process.env.VUE_APP_APPLICATION_URL}/dashboard`,
    });
  }
}
