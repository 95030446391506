import Rollbar from "rollbar";

const config: Rollbar.Configuration = {
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_APPLICATION_ENVIRONMENT,
    client: {
      javascript: {
        code_version: "1.0.0",
      },
    },
  },
};
export default config;
