import { AccountType } from "@/enums/AccountType";
import { Account } from "@/interfaces/Account";
import { Permission } from "@/models/role";
import { snakeCase } from "lodash";

export interface ApiUserManagementUser {
  id: string;
  naturalPersonId: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  account: Account;
  roles: Permission[];
}

// TODO: rename to profile?
export class UserManagementUser implements ApiUserManagementUser {
  id: string = "";
  naturalPersonId: string = "";
  email: string = "";
  firstName: string = "";
  lastName: string = "";
  fullName: string = "";
  account: Account = {
    name: "",
    id: "",
    accountNumber: "",
    type: AccountType.COMPANY,
  };
  roles: Permission[] = [];

  // casl model name
  static get modelName() {
    return "UserManagementUser";
  }

  static get modelTranslationField() {
    return `models.${snakeCase(this.modelName)}.model_name`;
  }

  constructor(user?: Partial<ApiUserManagementUser>) {
    Object.assign(this, user);
  }

  static fromApiUserManagementUser(
    user: ApiUserManagementUser
  ): UserManagementUser {
    return new UserManagementUser(user);
  }
}

export interface CreatedCustomer {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  gender: Gender;
  profiles: Profile[];
}

export interface NewContact {
  naturalPersonId: string;
  accountId: string;
  department: Division | null;
  jobtitle: string;
}

export interface Profile {
  id: string;
  naturalPersonId: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const nameUsages = [
  "own",
  "partner_own",
  "partner",
  "own_partner",
] as const;
export type NameUse = typeof nameUsages[number];

export const genders = ["male", "female"] as const;
export type Gender = typeof genders[number];

export const divisions = [
  "hr",
  "finance",
  "marketing",
  "facility",
  "inkoop",
  "verkoop",
  "it",
  "directie",
  "directie_minderheids",
  "directie_groot",
  "productie",
  "overig",
  "nvt_bij_contact_klant_persoon",
  "eigenaar",
] as const;
export type Division = typeof divisions[number];

export interface Customer {
  id: string;
  lastName: string;
  firstName: string;
  fullName: string;
  email: string;
  gender: Gender;
}

export interface Partner {
  firstname: string;
  middlename?: string;
  lastname: string;
}

export interface NewCustomer {
  nameUse: NameUse;
  gender: Gender;
  initials: string;
  firstname: string;
  middlename?: string;
  lastname: string;
  partner?: Partner;
  email: string;
  phoneNumber: string;
  accountId: string;
  department: Division | null;
  jobtitle: string;
}

export interface Credentials {
  id: string;
  type: string;
  name: string;
}

export interface AuthInfo {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  credentials: Credentials[];
}
