import NProgress from "nprogress";
import store from "@/store";
import { LAYOUT_ACTIONS } from "@/store/modules/layout";

export const finishNavigating = () => {
  const hamburger = document.querySelector("#nav__hamburger") as HTMLElement;
  if (hamburger && getComputedStyle(hamburger).display !== "none") {
    setTimeout(() => {
      store.dispatch(LAYOUT_ACTIONS.setMinimal, true);
    }, 100);
  }
  NProgress.done(true);
};
