















import { mixins } from "vue-class-component";
import TranslatableComponent from "../lib/mixins/translateable";
import { Component, Watch } from "vue-property-decorator";

import Loader from "@/components/Loader.vue";

@Component({
  components: { Loader },
})
export default class TheLoadingProfileIndicator extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  private dataTimer: ReturnType<typeof setTimeout> | null = null;

  private count: number = 10;

  @Watch("count", { immediate: true })
  private countdown(value: number) {
    if (value > 0) {
      setTimeout(() => this.count--, 1000);
    }
  }

  private refresh() {
    this.$rollbar.error("User stuck in profile load screen");
    this.$router.go(0);
  }
}
