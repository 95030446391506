















































































































import { Component } from "vue-property-decorator";
import { DocumentScope } from "@/models/document";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { namespace } from "vuex-class";

const user = namespace("user");

@Translatable("")
@Component
export default class TheFooter extends mixins(TranslatableComponent) {
  get currentYear() {
    const date = new Date();
    return date.getFullYear();
  }

  get tasksHref() {
    return `/taken?scope=${DocumentScope.SELF}`;
  }
}
