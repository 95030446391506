import axios, { AxiosInstance, AxiosResponse } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import { Discipline } from "@/models/disciplines";

export const personalisationClient = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_PERSONALISATION_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

export const personalisationApi = {
  disciplines(accountId: string): Promise<AxiosResponse<Discipline[]>> {
    return personalisationClient().get(`/disciplines/${accountId}`);
  },

  representatives(accountId: string): Promise<AxiosResponse<string[]>> {
    return personalisationClient().get(`/representatives/${accountId}`);
  },
};
