import { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";

const keycloakConfig: KeycloakConfig = {
  realm: process.env.VUE_APP_AUTH_REALM || "",
  url: process.env.VUE_APP_AUTH_SERVER_URL,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID || "",
};

const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: "check-sso",
  enableLogging: false,
};
export { keycloakConfig, keycloakInitOptions };
