import { Role } from "@/models/role";

export type ParticipantType = "EMPLOYEE" | "CLIENT";

export type ParticipantRole =
  | "owner"
  | "signer"
  | "sender"
  | "editor"
  | "unknown";

export interface Participant {
  xid: string;
  email: string;
  participantId: string;
  firstName: string;
  lastName: string;
  participantType: ParticipantType;
  role: ParticipantRole;
  hasSigned: boolean;
}

export const participantTypeFromRole = (
  role: Role
): ParticipantType | undefined => {
  const converter: Record<Role, ParticipantType> = {
    employee: "EMPLOYEE",
    customer: "CLIENT",
  };
  return converter[role];
};
