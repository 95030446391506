import { Component, Vue } from "vue-property-decorator";
import { isEmpty, snakeCase } from "lodash";
import { createDecorator } from "vue-class-component";

export type Theme =
  | "models"
  | "components"
  | "views"
  | "api"
  | "generic"
  | "login";

@Component
export default class TranslatableComponent extends Vue {
  namespace!: string;

  public model_human_attribute_name(
    clazz: any,
    key: string,
    params?: { [key: string]: string }
  ) {
    return this.$t(`models.${snakeCase(clazz.name)}.${key}`, params);
  }

  public model_human_attribute_value(
    clazz: string,
    key: string,
    params?: string[]
  ) {
    return this.$t(`models.${snakeCase(clazz)}.${key}`, params);
  }

  public translated_generic_value(key: string, params?: string[]) {
    return this.$t(`generic.${key}`, params);
  }

  public translated_api_value(key: string, params?: string[]) {
    return this.$t(`api.${key}`, params);
  }

  public translated_view_value(
    key: string,
    params?: { [key: string]: string }
  ) {
    const path = `${
      isEmpty(this.namespace) ? "" : `${this.namespace}.`
    }${snakeCase(this.$options.name)}.${key}`;
    return this.translated_raw_view_value(path, params);
  }

  public translated_raw_value(
    ns: Theme,
    fullpath: string,
    params?: { [key: string]: string }
  ) {
    return this.$t(`${ns}.${fullpath}`, params);
  }

  public translated_raw_view_value(
    fullpath: string,
    params?: { [key: string]: string }
  ) {
    return this.translated_raw_value("views", fullpath, params);
  }

  public translated_component_value(
    key: string,
    params?: { [key: string]: string }
  ) {
    const path = `${
      isEmpty(this.namespace) ? "" : `${this.namespace}.`
    }${snakeCase(this.$options.name)}.${key}`;
    return this.translated_raw_component_value(path, params);
  }

  public translated_raw_component_value(
    fullPath: string,
    params?: { [key: string]: string }
  ) {
    return this.$t(`components.${fullPath}`, params);
  }

  public translated_plural_component_value(
    key: string,
    amount: number,
    params?: { [key: string]: string }
  ) {
    const path = `${
      isEmpty(this.namespace) ? "" : `${this.namespace}.`
    }${snakeCase(this.$options.name)}.${key}`;
    return this.translated_plural_raw_component_value(path, amount, params);
  }

  public translated_plural_raw_component_value(
    fullPath: string,
    amount: number,
    params?: { [key: string]: string }
  ) {
    return this.$tc(`components.${fullPath}`, amount, params);
  }

  public hasTranslation(fullPath: string) {
    return this.$te(`${fullPath}`);
  }
}

export const Translatable = (namespace?: string) =>
  createDecorator((options, key) => {
    options.mixins = options.mixins?.concat(TranslatableComponent);
    options.data = () => {
      return { namespace };
    };
  });
