































import { AvailableIcons } from "@/interfaces/AvailableIcons";
import { NavigationEntry } from "@/interfaces/NavigationEntry";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavigationItem extends Vue {
  @Prop() public item!: NavigationEntry;
  @Prop() public minimal!: boolean;

  get iconName(): AvailableIcons {
    return this.item.requireStepUp ? "icon--eye" : this.item.icon;
  }

  private closeNav() {
    const nav = document.querySelector("nav");
    nav?.classList.remove("is-open");
  }

  get isRouteLink(): boolean {
    return this.item.link.startsWith("/");
  }
}
