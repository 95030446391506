






















































































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class VLogo extends Vue {
  private titleId: string = "title" + Math.random();
}
