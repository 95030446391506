import { DefaultProblem, Problem } from "@/api/problem/problem";
import { AxiosError } from "axios";

type TaskProblemTypes =
  | "https://nieuwmijn.jonglaan.nl/problem/task/not-found"
  | "https://nieuwmijn.jonglaan.nl/problem/account/no-vat-found";

export interface TaskProblem extends Problem {
  type: TaskProblemTypes;
}

export class TaskNotFoundProblem<
  T extends TaskProblem,
  D
> extends DefaultProblem<T, D> {
  constructor(error: AxiosError<T, D>) {
    super(error);
  }
}

export class VATNotFoundProblem<
  T extends TaskProblem,
  D
> extends DefaultProblem<T, D> {
  constructor(error?: AxiosError<T, D>) {
    super(error);
  }
}

export const problemInterceptor = <T extends TaskProblem, D>(
  error: AxiosError<T, D>
): Promise<DefaultProblem<T, D>> => {
  if (error.response?.headers["content-type"] === "application/problem+json") {
    const problem = error.response.data;
    switch (problem.type) {
      case "https://nieuwmijn.jonglaan.nl/problem/task/not-found":
        return Promise.reject(new TaskNotFoundProblem(error));
      case "https://nieuwmijn.jonglaan.nl/problem/account/no-vat-found":
        return Promise.reject(new VATNotFoundProblem(error));
      default:
        return Promise.reject(new DefaultProblem(error));
    }
  }
  return Promise.reject(error);
};
