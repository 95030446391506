












































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Translatable("")
@Component
export default class TheHeader extends mixins(TranslatableComponent) {
  @Prop()
  private page!: string | null;

  @Prop()
  private image!: string;

  public getImagePath(image: string, size: string): void {
    try {
      return require(`@/assets/headers/${size}/${image}`);
    } catch (err) {
      return require(`@/assets/headers/${size}/contact.jpg`);
    }
  }
}
