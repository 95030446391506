import axios, { AxiosInstance, AxiosPromise } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import { KnowledgeBaseData } from "@/interfaces/KnowledgeBase";
import QueryString from "qs";

export interface KnowledgeBaseGetParams {
  q?: string;
  pageNumber?: number;
  resultsPerPage?: number;
  disciplines?: string[];
  types?: string[];
  branches?: string[];
  fromDate?: number;
  toDate?: number;
}

export interface RepresentativeContactDetails {
  email: string;
  firstName: string;
  imageUrl: string;
  jobTitle: string;
  lastName: string;
  linkedInUrl: string;
  phoneNumber: string;
}

const axiosInstance = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_MAGNOLIA_CMS_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

const magnoliaCmsApi = {
  getArticles: ({
    q,
    pageNumber = 1,
    resultsPerPage = 20,
    disciplines,
    types,
    branches,
    fromDate,
    toDate,
  }: KnowledgeBaseGetParams): AxiosPromise<KnowledgeBaseData> => {
    return axiosInstance().get(`/.rest/knowledgebase/v1/search`, {
      params: {
        q: q,
        p: pageNumber,
        rpp: resultsPerPage,
        disciplines: disciplines,
        types: types,
        branches: branches,
        "publishDate.lowerBound": fromDate,
        "publishDate.upperBound": toDate,
      },
      paramsSerializer: (params) =>
        QueryString.stringify(params, { arrayFormat: "repeat" }),
    });
  },

  getRepresentativeContactDetails: (
    email: string
  ): AxiosPromise<RepresentativeContactDetails> => {
    return axiosInstance().get(`/.rest/knowledgebase/v1/contacts/${email}`);
  },
};

export default magnoliaCmsApi;
