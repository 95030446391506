




























import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Role } from "@/models/role";
import TaskHeader from "@/components/tasks/TaskHeader.vue";
import TaskList from "@/components/tasks/TaskList.vue";
import Tasks from "@/components/tasks/Tasks.vue";
import { namespace } from "vuex-class";
import SuggestedArticles from "@/components/knowledgeBase/SuggestedArticles.vue";

const user = namespace("user");
@Component({
  components: {
    SuggestedArticles,
    TaskHeader,
    TaskList,
    Tasks,
  },
})
@Translatable("dashboard")
export default class DashboardCustomer extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @user.State("firstName")
  private name!: string;

  private role: Role = "customer";

  get showArticles(): boolean {
    return process.env.VUE_APP_SHOW_KNOWLEDGE_BASE?.toLowerCase() === "true";
  }
}
