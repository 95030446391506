




import { debounce } from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class TheScrollToTop extends Vue {
  private timeout!: ReturnType<typeof setTimeout>;

  mounted() {
    document.addEventListener(
      "scroll",
      debounce(this.showIfNeeded, 200),
      false
    );

    this.$el.addEventListener(
      "click",
      () => {
        this.doScrolling(0, 200);
      },
      false
    );
  }

  private doScrolling(elementY: number, duration: number) {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start: number | null = null;

    window.requestAnimationFrame(function step(timestamp) {
      if (!start) {
        start = timestamp;
      }
      const time = timestamp - start;
      const percent = Math.min(time / duration, 1);

      window.scrollTo(0, startingY + diff * percent);

      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  }

  private show() {
    this.$el.classList.add("show");

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.hide();
    }, 2000);
  }

  private hide() {
    this.$el.classList.remove("show");
  }

  private showIfNeeded() {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    const h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    if (top > h / 1.5) {
      this.show();
    } else {
      this.hide();
    }
  }
}
