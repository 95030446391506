


























import { Component, Prop, Vue } from "vue-property-decorator";
import { Task } from "@/components/tasks/Task";
import { formatIsoDate } from "@/filters";
import { TaskCategory } from "@/models/task";

@Component({
  methods: { formatIsoDate },
})
export default class SingleTask extends Vue {
  @Prop() private task!: Task;

  @Prop()
  private taskCategory!: TaskCategory;

  @Prop()
  private selectedAccountId: string | undefined = undefined;
}
