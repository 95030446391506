export interface IEmployee {
  id: string;
  firstname: string;
  lastname: string;
  tekeningsbevoegd: boolean;
}

export class Employee implements IEmployee {
  id: string = "";
  firstname: string = "";
  lastname: string = "";
  tekeningsbevoegd: boolean = false;

  constructor(employee?: Partial<IEmployee>) {
    Object.assign(this, employee);
  }

  static fromIEmployee(employee: IEmployee): Employee {
    return new Employee(employee);
  }

  get displayName(): string {
    return `${this.firstname} ${this.lastname}`;
  }
}
